export default {
	namespaced: true,
	// modules: {}
	state: {
		takeNumber: 0,
		canTakeNumberList: [],
	},
	mutations: {
		SET_TAKE_NUMBER($, val) {
			$.takeNumber = val
		},
		SET_TAKE_NUMBER_LIST($, val) {
			$.canTakeNumberList = val
		},
	},
	actions: {},
}
