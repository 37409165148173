<template>
	<div
		class="input-tip"
		:style="{
			'margin-bottom':
				moreText.length > 0 && !getPasswordStrength ? '38px' : '22px',
		}"
	>
		<slot :v="isShow"></slot>
		<transition name="fade">
			<div class="red-txt" v-if="isShow">
				{{ remindText }}
				<ul v-if="!getPasswordStrength">
					<li v-for="(text, i) in moreText" :key="i" class="more-txt">
						{{ text }}
					</li>
				</ul>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	name: 'ByInputTip',
	data() {
		return {
			isShow: false,
			remindText: '',
			moreText: [],
		}
	},
	props: {
		text: {
			required: true,
			type: String | Array,
		},
		val: {
			required: true,
			type: String | Number,
		},
		validator: {
			required: false,
			type: Function,
		},
	},
	watch: {
		val() {
			this.checkVal()
		},
	},
	computed: {
		getPasswordStrength() {
			return this.$store.state.register.passwordStrength
		},
	},
	methods: {
		checkVal() {
			if (this.validator) {
				this.isShow = true
				this.remindText =
					typeof this.validator(this.val) === 'string'
						? this.validator(this.val)
						: ''
				setTimeout(() => {
					this.moreText =
						typeof this.validator(this.val) === 'object'
							? this.validator(this.val)
							: []
					if (this.moreText.length === 0 && this.remindText.length === 0) {
						this.isShow = false
					}
				})
			} else if (!this.validator && this.val === '') {
				this.remindText = this.text
				this.isShow = true
			} else {
				this.remindText = ''
				this.isShow = false
			}
		},
		clearWarning() {
			setTimeout(() => {
				this.isShow = false
			})
		},
	},
}
</script>
<style lang="sass" scoped>
.input-tip
  position: relative
  width: 100%
  .red-txt
    position: absolute
    color: #ef2727
    font-size: 14px
    margin: 5px 0 5px 130px
    .more-txt
      padding-bottom: 5px
</style>
