<template>
	<transition
		enter-active-class="enter-active"
		leave-active-class="leave-active"
		@enter="enter"
		@after-enter="afterEnter"
		@leave="leave"
		@after-leave="afterLeave"
	>
		<slot></slot>
	</transition>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'BySlider',
	// components: { COMPONENT_NAME },
	props: {
		do: {
			required: false,
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			timer: null,
		}
	},
	// computed: {},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		done(done) {
			clearTimeout(this.timer)
			this.timer = setTimeout(() => {
				done()
			}, 200)
		},
		enter(el, done) {
			if (this.do) {
				const setHeightAfterDone = () => {
					el.style.height = el.scrollHeight + 'px'
					this.done(done)
				}
				if (!el.style.height) {
					el.style.height = 0
					setTimeout(setHeightAfterDone)
				} else {
					setHeightAfterDone()
				}
			}
		},
		afterEnter(el) {
			if (this.do) {
				el.style.height = null
			}
		},
		leave(el, done) {
			if (this.do) {
				const setHeightAfterDone = () => {
					el.style.height = 0
					this.done(done)
				}
				if (!el.style.height) {
					el.style.height = el.offsetHeight + 'px'
					setTimeout(setHeightAfterDone)
				} else {
					setHeightAfterDone()
				}
			}
		},
		afterLeave(el) {
			if (this.do) {
				el.style.height = null
			}
		},
	},
}
</script>
<style lang="sass" scoped>
.enter-active,
.leave-active
  overflow: hidden
  transition: height .2s linear
</style>
