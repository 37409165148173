<template>
	<div class="v-text-field">
		<label :for="id" class="v-label">{{ label }}</label>
		<div :class="{ focused: isFocused }" class="input-container">
			<input
				:type="computedType"
				:id="id"
				:value="value"
				class="v-input"
				@focus="onFocus"
				@blur="onBlur"
				@keydown="doEnter"
				@input="back($event.target.value)"
			/>
			<img
				v-if="type === 'password' && isPasswordVisible"
				src="../assets/view.png"
				alt="Toggle visibility"
				class="toggle-visibility"
				@click="togglePasswordVisibility"
			/>
			<img
				v-if="type === 'password' && !isPasswordVisible"
				src="../assets/hide.png"
				alt="Toggle visibility"
				class="toggle-visibility"
				@click="togglePasswordVisibility"
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'VTextField',
	props: {
		id: {
			type: String,
			required: true,
		},
		label: {
			type: String,
			default: '標題',
		},
		type: {
			type: String,
			default: 'text',
		},
		value: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			isFocused: false,
			isPasswordVisible: false,
		}
	},
	computed: {
		computedType() {
			return this.type === 'password' && !this.isPasswordVisible
				? 'password'
				: 'text'
		},
	},
	methods: {
		back(val) {
			this.$emit('input', val)
		},
		onFocus() {
			this.isFocused = true
			this.$emit('onFocus')
		},
		onBlur() {
			this.isFocused = false
			this.$emit('onBlur')
		},
		doEnter(e) {
			if (e.key === 'Enter' || e === true) {
				this.$emit('doEnter', this.value)
			}
		},
		togglePasswordVisibility() {
			this.isPasswordVisible = !this.isPasswordVisible
		},
	},
}
</script>

<style scoped>
.v-text-field {
	width: 100%;
	position: relative;
}

.v-label {
	display: block;
	margin-left: 18px;
	margin-bottom: 10px;
	color: #555;
	font-size: 18px;
}

.input-container {
	display: flex;
	align-items: center;
	border: 1px solid #000;
	border-radius: 50px;
	font-size: 1.8rem;

	.v-input {
		width: 100%;
		padding: 10px 20px 10px 18px;
		border: none;
		outline: none;
		background: none;
		font-size: 1.8rem;
	}

	.toggle-visibility {
		cursor: pointer;
		height: 25px;
		width: 25px;
		margin-left: 5px;
		margin-right: 5px;
	}
}

.input-container.focused {
	border-color: #edb477;
	transition: width 0.3s;
}
</style>
