import request from './axios'
export default {
	// 取得新菜單
	getMenu(params) {
		return request.post(`/front/store/getMenu`, params)
	},
	// 新增訂單
	addOrder(data) {
		return request.post(`/front/order/add`, data)
	},
	// 確認付款
	confirmTransaction(code, params) {
		return request.get(`/callback/${code}/confirm`, { params })
	},
	// 確認付款
	cancelTransaction(code, params) {
		return request.get(`/callback/${code}/cancel`, { params })
	},
	// 取得取餐資訊
	getOrderInfo(params) {
		return request.get(`/front/order/getInfo`, { params })
	},
	// 取得訂單(依訂單狀態)
	getOrderByStatus(params) {
		return request.get(`/front/order/getListByStatus`, { params })
	},
	// 取得購物車餐點資訊
	getItems(params) {
		return request.post(`/front/store/getItemDetailList`, params)
	},
}
