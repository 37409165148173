<template>
	<div class="amount">
		<div class="minus" @click="handleMinus" :class="{ disable: value <= min }">
			<img src="../assets/reduce.svg" />
		</div>
		<!-- 修改为绑定inputValue -->
		<div class="number" @click="toggleInput" v-if="!editMode">{{ value }}</div>
		<input
			v-model="inputValue"
			v-if="editMode"
			@blur="handleInputBlur"
			@keyup.enter="handleInputBlur"
			type="number"
			class="number-input"
		/>
		<div
			class="plus"
			@click="handleIncrease"
			:class="{ disable: value >= max }"
		>
			<img src="../assets/plus.svg" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'ByAmountPicker',
	props: {
		value: {
			type: Number,
			required: false,
			default: 1,
		},
		min: {
			required: false,
			type: Number,
			default: 1,
		},
		max: {
			required: false,
			type: Number,
			default: 999,
		},
		isUpdate: {
			required: false,
			type: Boolean,
			default: true,
		},
	},
	model: {
		prop: 'value',
		event: 'updateCount',
	},
	data() {
		return {
			editMode: false, // 编辑模式状态
			inputValue: '',
		}
	},
	// computed: {},
	// watch: {},
	// created() {},
	mounted() {},
	watch: {
		value(newValue) {
			this.updateValue(newValue, true) // 确保外部value变化时同步更新
		},
	},
	// beforeDestroy() {},
	methods: {
		toggleInput() {
			this.editMode = !this.editMode
			this.inputValue = this.editMode ? this.value.toString() : ''
			this.$nextTick(() => {
				if (this.editMode) {
					this.$el.querySelector('.number-input').focus()
				}
			})
		},
		handleInputBlur() {
			let newValue = Number(this.inputValue)
			if (isNaN(newValue) || newValue < this.min) {
				newValue = this.min
			} else if (newValue > this.max) {
				newValue = this.max
			}
			this.updateValue(newValue)
			this.editMode = false
		},
		handleIncrease() {
			let currentValue = Number(this.value)
			if (currentValue + 1 > this.max) {
				this.updateValue(this.max, false) // 达到最大值时特殊处理
			} else {
				this.value = currentValue + 1
				this.$emit('onIncrease', this.value)
			}
			this.isUpdate && this.$emit('updateCount', this.value)
		},
		handleMinus() {
			let currentValue = Number(this.value)
			if (currentValue - 1 < this.min) {
				// this.updateValue(this.min, false); // 达到最小值时特殊处理
				this.$emit('onDelete')
				return
			}
			this.value = currentValue - 1
			this.$emit('onMinus', this.value)
			this.isUpdate && this.$emit('updateCount', this.value)
		},
		updateValue(newValue, isNormalUpdate = true) {
			if (this.value !== newValue) {
				this.value = newValue
				this.$emit('updateCount', newValue)
			}
			if (!isNormalUpdate && !this.isUpdate) {
				if (newValue < this.min) {
					this.$emit('updateCount', this.min)
				} else if (newValue > this.max) {
					this.$emit('updateCount', this.max)
				}
			}
		},
	},
}
</script>
<style lang="sass" scoped>
.amount
  display: flex
  align-items: center
  justify-content: space-around
  width: 120px
  color: #432d2c

  .minus, .plus
    width: 30px

  .number
    font-weight: 900
    font-size: 16px
    width: 55px
    text-align: center

  //.disable
  //  filter: grayscale(1)
  //  opacity: 0.3

  .number-input
    width: 40px
    font-size: 16px
</style>
