export const alert = (content, type = 'info') => {
	const div = document.createElement('div')
	div.className = 'my__alert ' + 'my__alert--' + type
	div.textContent = content
	document.body.appendChild(div)
	setTimeout(() => {
		document.body.removeChild(div)
	}, 3000)
}
export default function (Vue) {
	Vue.prototype.$alert = alert
}
