<template>
	<div class="by-icon" :style="styles">
		<img :src="src" />
	</div>
</template>
<script>
export default {
	name: 'ByIcon',
	props: {
		icon: {
			required: true,
		},
		type: {
			default: 'svg',
		},
		size: {
			default: '30',
		},
	},
	data() {
		return {
			src: require(`../assets/${this.icon}.${this.type}`),
		}
	},
	computed: {
		styles() {
			return `height:${this.size}px;width:${this.size}px;`
		},
	},
}
</script>

<style lang="sass" scoped>
.by-icon
  display: inline-block
  img
    height: 100%
    width: 100%
</style>
