import router from '@/router'
import store from '@/store'

export default {
	state: {
		kioskOrderRemark: '',
		isCancelOrderDialog: false,
		version: '24.05.09.1',
		maintenance: {
			modal: false, // 彈窗
			title: '', //標題
			message: '', //訊息
		},
	},
	mutations: {
		setMaintenance($, val) {
			$.maintenance = val
		},
	},
	actions: {
		guidePage(ctx) {
			const random = router.history.current.params.random
			if (random) {
				if (store.state.kiosk.is) {
					store.commit('kiosk/SET_IS', true)
					if (router.history.current.path === `/${random}/Refresh`) {
						location.reload()
					} else {
						router.push(`/${random}/Refresh?kiosk=${random}`)
					}
				} else {
					if (router.history.current.path === `/${random}/Store`) {
						location.reload()
					} else {
						router.push(`/${random}/Store`)
					}
				}
			} else {
				router.push('/null/Error')
			}
		},
	},
}
