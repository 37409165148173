<script>
export default {
	name: 'ByButton',
	props: {
		type: { default: 'normal' },
		// 樣式類型
		stl: {
			type: String,
			default: 'normal',
		},
		loading: {
			required: false,
			type: Boolean,
		},
		disabled: {
			type: Boolean,
			required: false,
			default: false,
		},
	},
	computed: {
		className() {
			let className = []
			if (this.disabled) {
				className.push('disabled')
			}
			switch (this.type) {
				case 'full':
					className.push('btn-full')
					break
				case 'normal':
					className.push('btn')
					break
			}
			switch (this.stl) {
				case 'main1':
					className.push('btn-main1')
					break
				case 'main2':
					className.push('btn-main2')
					break
				case 'main3':
					className.push('btn-main3')
					break
				case 'main4':
					className.push('btn-main4')
					break
				case 'main5':
					className.push('btn-main5')
					break
				case 'main6':
					className.push('btn-main6')
					break
				default:
					className.push('btn-normal')
			}
			return className.join(' ')
		},
	},
	methods: {
		handleClick() {
			if (!this.loading && !this.disabled) {
				this.$emit('click')
			}
		},
	},
	render() {
		return (
			<div onClick={this.handleClick} class={this.className}>
				{this.loading ? <ByLoader></ByLoader> : this.$slots.default}
			</div>
		)
	},
}
</script>

<style lang="sass" scoped>
@import '../styles/func.sass'
.btn-full
  width: 100%
  height: 40px
  border-radius: 10px
  cursor: pointer
  +fc

.btn
  cursor: pointer
  width: 125px
  height: 40px
  border-radius: 10px
  +fc

.btn-normal
  color: #fff
  background: #2196f3
  &:hover,&:active
    background: darken(#2196f3,10%)
.btn-main1
  color: #FFF
  background: #294B70
  &:hover,&:active
    background: darken(#294B70,10%)
.btn-main2
  color: #fff
  background: #432D2C
  &:hover,&:active
    background: lighten(#432D2C,10%)
.btn-main3
  color: #fff
  background: #4267B2
  &:hover,&:active
    background: lighten(#4267B2,10%)
.btn-main4
  color: #fff
  background: #EA4535
  &:hover,&:active
    background: lighten(#EA4535,10%)
.btn-main5
  color: #000000
  background: #edb37a
  &:hover,&:active
    background: lighten(#edb37a,10%)
.btn-main6
  color: #3b9eeb
  background: #ebf4ff
  &:hover,&:active
    background: lighten(#ebf4ff,1%)
.disabled
  color: rgba(0, 0, 0, 0.26)
  background: rgba(0, 0, 0, 0.12)
  pointer-events: none
</style>
