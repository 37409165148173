export default {
	namespaced: true,
	// modules: {}
	state: {
		openInfo: null,
		uri: null,
		menus: null,
		refreshMenu: false, // 刷新店家菜單
		refreshSetting: false, // 刷新店家設定
		whatsEatBinding: false, // WhatsEat綁定狀態
		whatsEatCouponAmount: null, //WhatsEat優惠卷數量
	},
	mutations: {
		SET_TAKE_OPEN_INFO($, value) {
			$.openInfo = value
		},
		SET_URI($, value) {
			$.uri = value
		},
		SET_MENUS($, value) {
			$.menus = value
		},
		SET_REFRESH_SETTING($, value) {
			$.refreshSetting = value
		},
		SET_REFRESH_MENU($, value) {
			$.refreshMenu = value
		},
		SET_WHATS_EAT_BINDING($, value) {
			$.whatsEatBinding = value
		},
		SET_WHATS_EAT_COUPON_AMOUNT($, value) {
			$.whatsEatCouponAmount = value
		},
	},
	actions: {},
}
