import orderApi from '../../apis/order'
export default {
	namespaced: true,
	// modules: {}
	state: {
		// 訂單列表
		list: [],
		finishOrder: false,
	},
	mutations: {
		SET_LIST($, val) {
			$.list = val
		},
		SET_FINISH_ORDER($, val) {
			$.finishOrder = val
		},
	},
	actions: {
		async getList(ctx) {
			const { rootGetters, rootState } = ctx
			ctx.commit('SET_LIST', [])
			const { isGuest } = rootGetters
			const { userId, storeId, guestId } = rootState.user
			try {
				ctx.commit(
					'SET_LIST',
					(
						await orderApi.getOrderByStatus({
							storeId: storeId,
							userId: isGuest ? guestId : userId,
							showType: 1, // 會員才能切換
							userType: isGuest ? 0 : 1,
						})
					).data,
				)
			} catch (error) {
				$devLog('error', error)
			}
		},
	},
}
