<template>
	<transition name="fade">
		<div class="by-dialog" v-if="value">
			<div class="by-dialog--black-screen"></div>
			<div class="by-dialog__content-wrap">
				<div class="el"></div>
				<div class="by-dialog__content" :style="{ width: width }">
					<ByCancel v-show="closable" @close="close"></ByCancel>
					<slot name="dialog-header" v-if="!noTitle">
						<div class="dialog-header">
							<div
								class="title"
								:style="
									closable
										? ''
										: title !== ''
										? { paddingTop: '20px' }
										: { paddingTop: 'initial' }
								"
							>
								{{ title }}
							</div>
						</div>
					</slot>
					<div class="dialog-body">
						<slot></slot>
					</div>
					<slot name="dialog-footer">
						<div class="dialog-footer">
							<ByButton
								v-if="!noCancelButton"
								:stl="cancelButtonStyle"
								@click="selectCancel"
								>{{ cancelText }}</ByButton
							>
							<ByButton stl="main1" @click="selectConfirm">
								{{ confirmText }}
							</ByButton>
						</div>
					</slot>
				</div>
				<div class="el"></div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	name: 'ByDialog',
	props: {
		width: {
			type: String | Number,
			required: false,
			default: '75%',
		},
		title: {
			required: false,
			type: String,
			default: '',
		},
		closable: {
			required: false,
			type: Boolean,
			default: true,
		},
		value: {
			required: false,
			type: Boolean,
			default: false,
		},
		cancelText: {
			required: false,
			type: String,
			default: '取消',
		},
		confirmText: {
			required: false,
			type: String,
			default: '確認',
		},
		noTitle: {
			required: false,
			type: Boolean,
			default: false,
		},

		//true時沒有取消按鈕
		noCancelButton: {
			required: false,
			type: Boolean,
			default: false,
		},
		//取消按鈕樣式class
		cancelButtonStyle: {
			required: false,
			type: String,
			default: 'main2',
		},
	},
	model: {
		prop: 'value',
		event: 'setModalVisible',
	},
	data() {
		return {
			isAppendBody: false,
		}
	},
	watch: {
		value(isShow) {
			if (isShow) {
				document.body.classList.add('no-scroll')
			} else {
				document.body.classList.remove('no-scroll')
			}
			this.$nextTick(() => this.checkFirstAppend(isShow))
		},
	},
	mounted() {
		this.checkFirstAppend(this.value)
	},
	beforeDestroy() {
		document.body.classList.remove('no-scroll')
		this.$parent.$el.append && this.$parent.$el.append(this.$el)
	},
	methods: {
		close() {
			this.$emit('setModalVisible', false)
			this.$emit('closeDialog', false)
		},
		selectCancel() {
			this.$emit('cancel')
		},
		selectConfirm() {
			this.$emit('confirm')
		},
		checkFirstAppend(isShow) {
			if (isShow && !this.isAppendBody) {
				document.body.append(this.$el)
			}
		},
	},
}
</script>

<style lang="sass">
.by-dialog
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  z-index: 1000
  &--black-screen
    position: absolute
    left: 0
    top: 0
    width: 100%
    height: 100%
    z-index: -1
    background: rgba(#000,.6)
  &__content-wrap
    display: flex
    align-items: center
    justify-content: center
    flex-wrap: wrap
    width: 100%
    overflow: auto
    -webkit-overflow-scrolling: touch
    height: 100vh
    scroll-behavior: smooth
    .el
      height: 80px
      width: 100%
  &__content
    background-color: #fff
    border-radius: 5px
    box-shadow: 3px 3px 2px 0px rgba(0, 0, 0, 0.3)
    .dialog
      &-header
        margin-bottom: 5px
        .title
          text-align: center
          font-size: 18px
          font-weight: bold
      &-body
        padding: 15px 20px
        text-align: justify
        letter-spacing: 1px
        line-height: 1.5
      &-footer
        display: flex
        justify-content: center
        align-items: center
        font-size: 17px
        padding: 0 20px 20px
        .btn
          border-radius: 100px
        .btn:nth-child(1)
          margin-right: 15px
</style>
