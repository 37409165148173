import Button from './Button'
import Input from './Input'
import Radio from './Radio'
import Checkbox from './Checkbox'
import Picker from './ByPicker'
import Slider from './BySlider'
import Icon from './Icon'
import Dialog from './Dialog'
import Cancel from './Cancel'
import Loader from './Loader'
import Identify from './Identify'
import PickerBar from './PickerBar'
import TopBtn from './TopBtn'
import AmountPicker from './AmountPicker'
import Footer from './Footer'
import OrderControl from './OrderControl'
import InputTip from './InputTip'
import CartGrid from './CartGrid'
import CompareCartConfirm from './CompareCartConfirm'
import ClearCartConfirm from './ClearCartConfirm'
import VuePicker from './VuePicker'
import LogoutConfirm from './LogoutConfirm'
import VTextField from './VTextField'
import MaintenanceModal from '@/components/MaintenanceModal.vue'
// import _FullScreenLoader from './FullScreenLoader'

const components = [
	Button,
	Input,
	Radio,
	Checkbox,
	Picker,
	Slider,
	Icon,
	Dialog,
	Cancel,
	Loader,
	Identify,
	PickerBar,
	TopBtn,
	AmountPicker,
	Footer,
	OrderControl,
	InputTip,
	CartGrid,
	CompareCartConfirm,
	ClearCartConfirm,
	VuePicker,
	LogoutConfirm,
	VTextField,
	MaintenanceModal,
	/*_FullScreenLoader*/
]

export default Vue => {
	components.forEach(e => {
		Vue.component(e.name, e)
	})
}
