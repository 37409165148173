export default {
	namespaced: true,
	state: {
		is: false,
	},
	mutations: {
		SET_IS(state, value) {
			state.is = value
		},
	},
}
