<template>
	<div
		class="to-top"
		:style="{
			transform,
			bottom,
		}"
		@click="toTop"
	></div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'ByTopBtn',
	// components: { COMPONENT_NAME },
	props: {
		bottom: {
			type: String,
			default: '15px',
		},
	},
	data() {
		return {
			transform: 'translateY(100px)',
		}
	},
	// computed: {},
	// watch: {},
	// created() {},
	mounted() {
		document.addEventListener('scroll', this.handleScroll)
	},
	beforeDestroy() {
		document.removeEventListener('scroll', this.handleScroll)
	},
	methods: {
		toTop() {
			window.scrollTo({ top: 0, behavior: 'smooth' })
		},
		handleScroll(evt, ele) {
			if (window.scrollY > 50) {
				this.transform = 'translateY(0px)'
			} else {
				this.transform = 'translateY(100px)'
			}
		},
	},
}
</script>
<style lang="sass" scoped>
.to-top
  transition: all 0.3s
  position: fixed
  background: #432D2C
  width: 45px
  height: 45px
  border-radius: 50%
  right: 15px
  margin-bottom: constant(safe-area-inset-bottom)
  margin-bottom: env(safe-area-inset-bottom)
  +fc
  &:before
    content: ''
    border: solid #fff
    border-width: 0 2px 2px 0
    padding: 6px
    margin-top: 6px
    transform: rotate(225deg)
</style>
