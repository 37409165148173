export default {
	namespaced: true,
	// modules: {}
	state: {
		cacheCart: null, // {}
		cacheCartIndex: 0,
	},
	mutations: {
		setCacheCart($, { data, index }) {
			$.cacheCart = data
			$.cacheCartIndex = index
		},
	},
	actions: {},
}
