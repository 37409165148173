<template>
	<ByDialog v-model="isShow" :closable="false">
		<template v-if="confirmCartItemsMessage.type === 'success'">
			<div class="text-center">{{ confirmCartItemsMessage.text[0] }}</div>
			<div class="text-center" style="margin-top: 10px">
				您的取餐號碼：{{ confirmCartItemsMessage.text[1] }}
			</div>
		</template>
		<template v-else-if="confirmCartItemsMessage.type === 'error'">
			<div class="text-center">{{ confirmCartItemsMessage.text[0] }}</div>
		</template>

		<template slot="dialog-footer">
			<div class="dialog-footer">
				<ByButton stl="main1" @click="handleCloseConfirmCartItems"
					>知道了
				</ByButton>
			</div>
		</template>
	</ByDialog>
</template>

<script>
import { getOrderDetailList } from '@/utils/order'

export default {
	name: 'ByCompareCartConfirm',
	data() {
		return {
			isShow: false,
			confirmCartItemsMessage: '',
		}
	},
	computed: {
		cart() {
			return this.$store.getters['user/currCart']
		},
		isKiosk() {
			return this.$store.getters.isKiosk
		},
	},
	methods: {
		handleCloseConfirmCartItems() {
			this.isShow = false
			if (this.cart.length === 0 && !this.isKiosk) {
				setTimeout(() => {
					this.$router.push(`/${this.$route.params.random}/Store`)
				}, 100)
			}
		},
		async confirm() {
			if (this.cart.length > 0) {
				const { message, status } = await this.$store.dispatch(
					'user/compareCart',
				)
				if (status === 1) {
					this.isShow = true
					this.confirmCartItemsMessage = {
						type: 'error',
						text: [message],
					}
					return
				}

				if (this.isKiosk) {
					await this.goMeal()
					return
				}

				if (this.$router.history.current.name !== 'Order') {
					this.$router.push('Order')
				} else {
					return true
				}
			} else {
				this.$alert('請先選擇商品', 'danger')
			}
		},
		async goMeal() {
			const { cart } = this
			const { storeId } = this.$store.state.user
			try {
				const orderDetailList = getOrderDetailList(cart)

				const order = await this.$store.dispatch('socket/addOrder', {
					storeId,
					tableware: null,
					orderDetailList: orderDetailList,
					machineType: 'KIOSK',
					remark:
						this.$store.state.kioskOrderRemark !== ''
							? this.$store.state.kioskOrderRemark
							: undefined,
					takeTime: null,
					name: '',
					phone: '',
					discountPrice: 0,
					entertain: false,
					paid: false,
				})
				this.$store.state.kioskOrderRemark = ''
				// this.isFinishedOrderModal = true
				this.$store.commit('user/SET_CART_ITEM', [])
				const res = (
					await this.$api.order.getOrderInfo({
						hashId: order.data.hashId,
					})
				).data
				this.isShow = true
				this.confirmCartItemsMessage = {
					type: 'success',
					text: [
						'點餐成功！請記住您的取餐號碼並等待店家叫號取餐',
						res.takeNumber,
					],
				}
				this.$store.state.kioskOrderRemark = ''
				this.$emit('onSubmit')
			} catch (err) {
				// 若餐點可能有更動，則返回錯誤
				if (
					err.errorCode &&
					(err.errorCode.startsWith('ORDER_') ||
						err.errorCode.startsWith('OPTION_'))
				) {
					if (err.errorCode.startsWith('ORDER_')) {
						this.$alert(err.message, 'danger')
					} else {
						const customErrMessage = err.message + ' ， 請修改餐點!'

						this.$alert(customErrMessage, 'danger')
					}
				} else {
					this.$alert('新增訂單異常!請刷新再試', 'danger')
				}
			}
		},
	},
}
</script>

<style scoped lang="sass"></style>
