import { getUserId } from '../utils/data-check'
const getters = {
	token: state => state.user.token,
	takeway: state => state.user.takeway,
	cartQuantity: state => {
		const user = state.user
		const cart = user.cart[getUserId(user.userId)]
		return cart ? cart[user.storeId].length : 0
	},
	storeName: state => state.user.storeName,
	isGuest: state => state.user.userId == null,
	isKiosk: state => state.kiosk.is,
	isOpen: state => {
		const { openInfo } = state.store
		if (!openInfo) return false
		if (openInfo.open) return true
		return false
	},
	isBooking: state => {
		const { openInfo } = state.store
		if (!openInfo) return false
		if (openInfo.bookingSwitch) return true
		return false
	},
	isMobileOrder: state => {
		const { openInfo } = state.store
		if (openInfo.mobileOrderSwitch) return true
		return false
	},
	canBuy: state => {
		const { openInfo } = state.store
		if (state.kiosk.is) return true
		if (!openInfo) return false
		if ((openInfo.open || openInfo.bookingSwitch) && openInfo.mobileOrderSwitch)
			return true
		return false
	},
}
export default getters
