<template>
	<div>
		<div class="by-picker" @click="visible = true">{{ showVal }}</div>
		<VuePicker
			ref="pkr"
			class="pkr"
			:data="data"
			:visible.sync="visible"
			:title="placeholder"
			cancelText="取消"
			confirmText="確認"
			showToolbar
			maskClick
			@change="onChange"
			@cancel="onCancel"
			@confirm="onConfirm"
		/>
	</div>
</template>

<script>
import VuePicker from './fork/VuePickers'

export default {
	name: 'ByVuePicker',
	components: {
		VuePicker,
	},
	props: {
		value: String | Number | Boolean,
		placeholder: { type: String, default: '請選擇' },
		data: { type: Array, default: () => [] },
		format: { type: Function, default: null },
	},
	data() {
		return {
			visible: false,
		}
	},
	computed: {
		current() {
			const { value, data, format } = this
			if (Array.isArray(value)) {
				const els = data.map((e, i) => e.find(f => f.value === value[i]))
				return format ? format(els) : els.map(e => e.label)
			} else {
				const el = data[0]?.find(e => e.value === value)
				if (el == null) return null
				return format ? format(el) : el.label
			}
		},
		showVal() {
			return this.value ? this.current : this.placeholder
		},
	},
	methods: {
		onConfirm(e) {
			this.visible = false
			this.$emit(
				'input',
				Array.isArray(e) && e.length > 1 ? e.map(f => f.value) : e[0].value,
			)
		},
		onCancel() {
			this.visible = false
		},
		onChange(e) {
			this.$emit('change', e)
		},
		setColumn(i, column) {
			this.$refs.pkr?.setColumn(i, column)
		},
	},
}
</script>

<style lang="sass" scoped>
.by-picker
  border: 1px solid #000
  padding: 10px
  position: relative
  &::after
    content: ''
    position: absolute
    right: 10px
    top: calc(50% + 3px)
    transform: translateY(-50%)
    border-top: 6px solid #000
    border-bottom: 4px solid transparent
    border-left: 4px solid transparent
    border-right: 4px solid transparent
.pkr
  &::v-deep
    .header
      .left, .right
        width: auto
    .content
      .colums
        position: relative
        z-index: 1
    .hairline
      z-index: 0
      &::after
        background: #ebedf0
        border-radius: 8px
        overflow: hidden
        width: calc(200% - 40px)
        left: calc(-50% + 20px)
</style>
