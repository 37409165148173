<template>
	<div class="picker-bar">
		<div
			v-for="item in items"
			@click="setCurrent(item.id)"
			:key="item.id"
			:class="{ active: value === item.id, item: true }"
			class="picker-tab"
		>
			{{ item.name }}
		</div>
		<div class="underline" :style="underlineStyle" />
	</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'ByPickerBar',
	// components: { COMPONENT_NAME },
	props: {
		items: {
			required: true,
			type: Array,
		},
		value: {
			required: true,
			type: String | Number,
		},
	},
	model: {
		prop: 'value',
		event: 'update',
	},
	data() {
		return {
			underlineStyle: {},
		}
	},
	// computed: {},
	// watch: {},
	// created() {},
	mounted() {
		this.setStyle()
	},
	// beforeDestroy() {},
	methods: {
		setCurrent(id) {
			if (this.value !== id) {
				this.$emit('update', id)
				this.$emit('onChange', id)
				this.$nextTick(() => {
					this.setStyle()
				})
			}
		},
		setStyle() {
			const index =
				this.items.findIndex(e => e.id === this.value) !== -1
					? this.items.findIndex(e => e.id === this.value)
					: '0'
			const pickerTab = document.getElementsByClassName('picker-tab')[index]
			this.underlineStyle = {
				width: `${pickerTab.offsetWidth - (index === 0 ? 32.5 : 30)}px`,
				transform: `translateX(${
					pickerTab.offsetLeft + (index === 0 ? 17.5 : 15)
				}px)`,
			}
		},
	},
}
</script>
<style lang="sass" scoped>
.picker-bar
  box-shadow: 0px 3px 10px rgba(0,0,0,0.16)
  height: 40px
  width: 100vw
  overflow-x: auto
  white-space: nowrap
  color: #434343
  position: relative
  font-size: 16px
  >.item
    display: inline-block
    padding: 13px 15px 8px
    // &::before
    //   content: ''
    //   left: 0
    //   bottom: 0
    //   position: absolute
    //   left: 0
    //   bottom: 0
    //   background: #fff
    //   height: 3px
    //   width: 15px
    //   z-index: 2
    &:first-child
      padding-left: 17.5px
    &.active
      color: #000
  .underline
    position: absolute
    height: 3px
    left: 0
    bottom: 0
    background: #294B70
    transition: all 0.3s
    border-radius: 30px
</style>
