import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import store from '@/store'
import order from '@/apis/order'
import router from '@/router'
import { emitter } from '@/utils/mitt'

export default {
	namespaced: true,
	// modules: {}
	state: {
		socket: null,
		stompClient: null,
		errorTimeout: null,
		isForce: false,
		onWsWatchOrder: null, // {orderStatusId: number, orderId: number}
	},
	mutations: {
		setOnWsWatchOrder($, id) {
			$.onWsWatchOrder = id
		},
	},
	actions: {
		successCallback(ctx) {
			const { state, rootState } = ctx
			$devLog('%c連接成功', 'color:green;background-color:#fff;')
			if (!store.getters.isKiosk) {
				state.stompClient.subscribe('/user/store', ({ body }) => {
					const JSONBody = JSON.parse(body)
					$devLog('/user/store', JSONBody)
					store.commit('takeMeal/SET_TAKE_NUMBER', JSONBody.maxTakeNumber)
					store.commit(
						'takeMeal/SET_TAKE_NUMBER_LIST',
						JSONBody.canTakeNumberList,
					)
				})
				state.stompClient.subscribe('/user/subscribe', ({ body }) => {
					const JSONBody = JSON.parse(body)
					$devLog('/user/subscribe', JSONBody)
					const { /*id, */ orderId, orderStatus, paidStatus, id, status } =
						JSONBody

					const save = {
						orderId: id,
						orderStatus: status,
					}

					if (router.history.current.name === 'TakeMeal')
						emitter.emit('TakeMeal_PAID', { id, paidStatus })

					if (router.history.current.name !== 'TakeMeal' && status === 3) {
						router.push(
							`/${router.history.current.params.random}/TakeMeal/${id}`,
						)
					} else if (status === 5 || status === 6) {
						store.state.isCancelOrderDialog = true
						ctx.commit('setOnWsWatchOrder', save)
					} else {
						ctx.commit('setOnWsWatchOrder', save)
					}
				})
			}
			// 訂閱刷新通道
			if (store.state.user.storeId) {
				state.stompClient.subscribe(
					`/refresh/${store.state.user.storeId}`,
					async msg => {
						const data = JSON.parse(msg.body)
						$devLog('/refresh', data)
						if (store.state.user.storeId !== data.storeId) {
							$devLog('店家id不合')
							return
						}
						if (data.type === 'SETTING') {
							$devLog('刷新店家設定')
							ctx.commit('store/SET_REFRESH_SETTING', true, { root: true })
						}

						if (data.type === 'MENU') {
							// 當前在pos頁才刷新
							if (router.history.current.name === 'Store') {
								$devLog('刷新菜單')
								ctx.commit('store/SET_REFRESH_MENU', true, { root: true })
							}
						}
					},
				)
			}
			// 訂閱平台公告通道
			state.stompClient.subscribe(`/announcement/platform`, async msg => {
				const data = JSON.parse(msg.body)
				$devLog('/announcement/platform', data)
				if (data.event === 'START_MAINTENANCE') {
					ctx.commit(
						'setMaintenance',
						{
							modal: true,
							title: data.title,
							message: data.message,
						},
						{ root: true },
					)
					return
				}

				if (data.event === 'END_MAINTENANCE') {
					ctx.commit(
						'setMaintenance',
						{
							modal: false,
							title: '',
							message: '',
						},
						{ root: true },
					)
				}
			})
		},
		onErrorReConnection(ctx) {
			const { dispatch, state } = ctx
			$devLog(`%c斷線續連開始`, 'color:red;background-color:#fff;')
			state.socket = null
			state.stompClient = null
			if (state.isForce === false) {
				state.isForce = true
				dispatch('connection')
			} else {
				clearTimeout(state.errorTimeout)
				state.errorTimeout = setTimeout(() => dispatch('connection'), 3000)
			}
		},
		connection(ctx) {
			const { state, dispatch } = ctx
			if (state.socket == null && state.stompClient == null) {
				state.socket = new SockJS(
					`${process.env.VUE_APP_BASE_API}/app/websocket`,
				)
				state.stompClient = Stomp.over(state.socket)
				state.stompClient.debug = null
			}

			state.stompClient.connect(
				{
					account: store.state.user.guestId,
					store: store.state.user.storeId,
					buyerType: store.getters.isKiosk ? 'USER' : 'GUEST',
				},
				() => {
					state.isForce = false
					dispatch('successCallback')
				},
				err => dispatch('onErrorReConnection'),
			)
		},
		disconnect(ctx) {
			const { state } = ctx
			if (state.stompClient != null) {
				state.stompClient.disconnect()
				clearInterval(state.timer)
			}
		},
		initWebSocket(ctx) {
			const { dispatch, state } = ctx
			dispatch('connection')
			state.timer = setInterval(() => {
				try {
					state.stompClient.send('/app/connect')
				} catch (err) {
					$devLog('心跳異常')
					dispatch('connection')
				}
			}, 60000)
		},
		async addOrder(ctx, data) {
			return await order.addOrder(data)
		},
	},
}
