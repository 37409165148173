import axios from 'axios'
import request from '@/apis/axios'

const whatsEatApiBase = axios.create({
	baseURL: `https://api-v3.whatseat.store/api/v1`,
	timeout: 60000,
})

const list = {
	// 檢查綁定與檢查優惠卷
	checkBinding: params => whatsEatApiBase.get(`/shop/${params}`),
}

export default Vue => {
	Vue.prototype.$whatsEatApi = list
}
