<template>
	<ByDialog
		class="clear-cart-confirm"
		:value="value"
		width="465px"
		title="確定清空購物車嗎"
		@closeDialog="onCancel"
	>
		<p>按下確定將清空購物車</p>
		<template slot="dialog-footer">
			<div class="dialog-footer">
				<ByButton stl="main2" @click="onCancel">取消</ByButton>
				<ByButton stl="main1" @click="onClear">確定</ByButton>
			</div>
		</template>
	</ByDialog>
</template>
<script>
export default {
	name: 'ByClearCartConfirm',
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false,
		},
	},
	model: {
		prop: 'value',
		event: 'update',
	},
	data() {
		return {}
	},
	methods: {
		onCancel() {
			this.$emit('update', false)
		},
		onClear() {
			this.$store.state.kioskOrderRemark = ''
			this.$store.commit('user/CLEAR_CART')
			this.$alert(`成功清空購物車`, 'success')
			this.$emit('update', false)
		},
	},
}
</script>
<style lang="sass">
.clear-cart-confirm
  .dialog-body
    text-align: center
</style>
