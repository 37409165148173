<script>
export default {
	name: 'ByPicker',
	props: {
		multiple: {
			//單選radio 多選checkbox
			default: false,
			type: Boolean,
		},
	},
	render() {},
}
</script>

<style lang="sass" scoped></style>
