<template>
	<div class="order-control immerse-control-panel">
		<ByButton stl="main2" @click="onBack" :loading="loadings[0]">{{
			names[0]
		}}</ByButton>
		<ByButton stl="main1" @click="onNext" :loading="loadings[1]">{{
			names[1]
		}}</ByButton>
	</div>
</template>
<script>
// import COMPONENT_NAME from '@/components/COMPONENT_NAME'
export default {
	name: 'ByOrderControl',
	// components: { COMPONENT_NAME },
	props: {
		names: {
			required: false,
			type: Array,
			default: () => [],
		},
		events: {
			required: false,
			type: Array,
			default: () => [],
		},
		loadings: {
			required: false,
			type: Array,
			default: () => [false, false],
		},
	},
	// data() {return {}},
	// computed: {},
	// watch: {},
	// created() {},
	// mounted() {},
	// beforeDestroy() {},
	methods: {
		onNext() {
			const {
				events: [, fn],
			} = this
			fn()
		},
		onBack() {
			const {
				events: [fn],
			} = this
			fn()
		},
	},
}
</script>
<style lang="sass" scoped>
.order-control
  border-radius: 25px 25px 0 0
  box-shadow: 0 -5px 12px rgba(0,0,0,0.16)
  position: fixed
  bottom: 0
  width: 100%
  height: 75px
  display: flex
  justify-content: space-between
  align-items: center
  background: #fff
  padding: 0 17.5px
  z-index: 1
  .btn
    width: 48.5%
    border-radius: 50px
// 沉浸用控制框
.immerse-control-panel
  @media screen and (min-width: 600px)
    justify-content: center
    .btn
      width: 205px
      &:first-child
        margin-right: 15px
</style>
