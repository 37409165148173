<template>
	<div class="by-checkbox">
		<label :style="{ opacity: disabled ? 0.5 : 1, width: width }">
			<input
				type="checkbox"
				ref="checkbox"
				:value="name"
				@change="handleChange"
				v-model="model"
				:disabled="disabled"
			/>
			<div class="checkbox"></div>
			<span class="title">{{ title }}</span>
		</label>

		<slot></slot>
	</div>
</template>
<script>
export default {
	name: 'ByCheckbox',
	props: {
		name: String,
		title: String,
		value: Boolean,
		disabled: {
			type: Boolean,
			default: false,
		},
		width: {
			type: String | Number,
			required: false,
			default: '40%',
		},
	},
	computed: {
		model: {
			set(val) {
				this.$emit('input', val)
			},
			get() {
				return this.value
			},
		},
	},
	methods: {
		handleChange(ev) {
			let value
			if (ev.target.checked) {
				value = this.name === undefined ? true : this.name
			} else {
				value = this.name === undefined ? false : this.name
			}
			this.$emit('change', value, ev)
		},
	},
}
</script>

<style lang="sass" scoped>
*
  box-sizing: border-box
.by-checkbox
  display: flex
  align-items: center
  justify-content: space-between

  label
    width: 40%
    display: flex
    align-items: center
    .checkbox
      transition: all 0.3s
    input
      display: none
      &+.checkbox
        min-width: 27px
        width: 27px
        height: 27px
        border: 1px solid #8e8e8e
        border-radius: 5px
    input[type="checkbox"]:checked
      &+.checkbox
        border: 0px
        background: #294B70
        display: flex
        justify-content: center
        align-items: center
        &:before
          content: ''
          border: solid #fff
          border-width: 0 3px 3px 0
          padding: 4px
          margin-bottom: 4px
          transform: rotate(45deg)
  .title
    margin-left: 10px
</style>
