<template>
	<ByDialog
		class="clear-cart-confirm"
		:value="value"
		width="465px"
		title="確定要登出嗎"
		@closeDialog="onCancel"
	>
		<p>按下登出將您登出前台點餐系統</p>
		<template slot="dialog-footer">
			<div class="dialog-footer">
				<ByButton stl="main2" @click="onCancel">取消</ByButton>
				<ByButton stl="main1" @click="onLogout">登出</ByButton>
			</div>
		</template>
	</ByDialog>
</template>
<script>
export default {
	name: 'ByLogoutConfirm',
	props: {
		value: {
			type: Boolean,
			required: true,
			default: false,
		},
	},
	model: {
		prop: 'value',
		event: 'update',
	},
	data() {
		return {}
	},
	methods: {
		onCancel() {
			this.$emit('update', false)
		},
		async onLogout() {
			this.$emit('update', false)
			await this.$api.user.logout()
			this.$store.commit('kiosk/SET_IS', false)
			this.$store.commit('user/SET_GUEST_ID', null)
			this.$store.state.kioskOrderRemark = ''
			this.$store.commit('user/CLEAR_CART')
			await this.$router.replace(
				`/${this.$route.params.random}/Refresh?kiosk=${this.$route.params.random}`,
			)
		},
	},
}
</script>
<style lang="sass">
.clear-cart-confirm
  .dialog-body
    text-align: center
</style>
